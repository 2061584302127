<template>
  <div>
    <app-datatable
      add-permission="add_sale"
      show-branch-filter
      show-inventory-filter
      :headers="datatable.headers"
      :end-point="endPoint"
      :table-slots="datatable.tableSlots"
      :reset-table-data="datatable.resetTableData"
      :update-table-data="datatable.updateTableData"
      @data-loaded="handleDataLoaded()"
      @add-new="addNew()"
    >
      <template v-slot:index="slotProp">
        {{ slotProp.index + 1 }}
      </template>

      <template v-slot:is_cash="slotProp">
        <v-chip
          label
          small
          outlined
          :color="slotProp.item.is_cash ? 'primary' : 'warning'"
        >
          {{ slotProp.item.is_cash ? $t('Cash') : $t('Debt') }}
        </v-chip>
      </template>

      <template v-slot:net_price="slotProp">
        $ {{ $_format_number(slotProp.item.net_price) }}
      </template>

      <template v-slot:action_date="slotProp">
        {{ $_format_date(slotProp.item.action_date) }}
      </template>

      <!-- <template v-slot:created_at="slotProp">
        <span class="d-inline-block dir-ltr">
          {{ $_format_date_time(slotProp.item.created_at) }}
        </span>
      </template> -->

      <!-- Actions -->
      <template
        v-if="$_cans(['change_sale', 'delete_sale'])"
        v-slot:actions="slotProp"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdil-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="viewRow(slotProp.item.id)">
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  class="fs-18"
                >
                  mdil-printer
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('View') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$_can('change_sale')"
              @click="updateRow(slotProp.item)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="info"
                  class="fs-18"
                >
                  mdil-pencil
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Edit') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="$_can('delete_sale')"
              @click="deleteRow(slotProp.item.id)"
            >
              <v-list-item-icon class="me-2">
                <v-icon
                  small
                  color="error"
                  class="fs-18"
                >
                  mdil-delete
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ $t('Delete') }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </app-datatable>

    <!------------------- Dialogs ------------------->
    <add-edit
      :add-edit-dialog="addEditDialog"
      :form-data="formData"
      :edit-mode="editMode"
      @close="addEditDialog = false"
      @reset-table-data="datatable.resetTableData = true"
      @update-table-data="datatable.updateTableData = true"
    />

    <edit-monthly-plan
      :monthly-plan-dialog="monthlyPlanDialog"
      :form-data="monthlyPlanData"
      :customer-data="customerData"
      @close="monthlyPlanDialog = false, monthlyPlanData = {}, customerData = {}"
    />

    <view-sale
      :view-dialog="viewDialog"
      :form-data="formData"
      @close="viewDialog = false"
    />
  </div>
</template>

<script>
import axios from 'axios'
import AddEdit from './AddEdit.vue'
import ViewSale from './ViewSale.vue'
import EditMonthlyPlan from './EditMonthlyPlan.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    AddEdit,
    ViewSale,
    EditMonthlyPlan,
  },

  data() {
    return {
      addEditDialog: false,
      viewDialog: false,
      formData: {},
      editMode: false,
      monthlyPlanData: {},
      customerData: {},
      monthlyPlanDialog: false,
      datatable: {
        resetTableData: false,
        updateTableData: false,
        headers: [
          this.$_index_column(),
          { text: this.$t('Inventory'), value: 'inventory', sortable: false },
          { text: this.$t('Customer'), value: 'customer.name', sortable: false },
          { text: this.$t('Customer Phone'), value: 'customer.phone1', sortable: false },
          { text: this.$t('Invoice No.'), value: 'invoice_no', sortable: true },
          { text: this.$t('Net Price'), value: 'net_price', sortable: true },
          { text: this.$t('Cash or Debt'), value: 'is_cash', sortable: true },
          { text: this.$t('Note'), value: 'note', sortable: true },
          { text: this.$t('User'), value: 'user', sortable: true },
          { text: this.$t('Action Date'), value: 'action_date', sortable: true },
          // { text: this.$t('Created At'), value: 'created_at', sortable: true },
          { text: this.$t('Actions'), value: 'actions', sortable: false, align: 'center' },
        ],
        tableSlots: [
          'index',
          'is_cash',
          'net_price',
          'action_date',
          'created_at',
          'actions',
        ]
      },
      endPoint: 'sale/'
    }
  },

  mounted() {
    this.$_section_title({ title: 'Sales', icon: 'mdil-currency-usd' })
    this.getCustomersList()
  },

  methods: {
    ...mapActions(['getCustomersList']),

    handleDataLoaded() {
      this.datatable.resetTableData = false
      this.datatable.updateTableData = false
    },

    addNew() {
      this.formData = {
        action_date: this.$_format_date_for_picker(new Date()),
        is_cash: false,
        is_by_purchase_price: false,
        details: []
      }
      this.editMode = false
      this.addEditDialog = true
    },

    viewRow(id) {
      axios.get(`sale/${id}/`).then(res => {
        this.formData = { ...res.data }
        this.viewDialog = true
      })
    },

    updateRow(data) {
      axios.get(`sale/${data.id}/`).then(res => {
        this.formData = { ...res.data }
        this.editMode = true
        this.addEditDialog = true
      })
    },

    deleteRow(id) {
      this.$_confirm().then(confirmed => {
        if (confirmed) {
          axios.delete(this.endPoint + id + '/').then(res => {
            this.$_notify('Deleted successfully')
            this.datatable.resetTableData = true
            if (res.data.change_plan && res.data.monthly_plan) {
              this.monthlyPlanData = res.data.monthly_plan
              this.customerData = res.data.customer
              this.monthlyPlanData.next_receipt_date = null
              this.monthlyPlanDialog = true
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>